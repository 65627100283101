import React from "react";

function Disclaimer() {
    return(
        <>
        <div className="page-container grid justify-center justify-items-center">
                Effective Date: May 31st, 2024

<h1>DISCLAIMER</h1>

<h6>GENERAL INFORMATION</h6>
 
<p>azrieljack (“company”, “I”, “we” or “us”) provides general educational information on various topics on this website as a public service, which should not be construed as professional or financial. These are my personal opinions only. 

The term “you” refers to anyone who uses, visits and/or views the website. 
You agree to accept all parts of this Disclaimer by viewing this website or anything made available on or through this website, including but not limited to programs, products, services, opt-in gifts, e-books, videos, webinars, blog posts, e-newsletters, consultations, e-mails, social media, and/or other communication (collectively referred to as "Website").
Please read this Disclaimer carefully, and I reserve the right to modify it at any time without notice.  By visiting and using this website, you accept and agree to be bound by this Disclaimer along with our Terms and Conditions and Privacy Policy. Your continued use of our website, programs, products and/or services constitutes your acceptance of future changes and updates to this Disclaimer. You must not access or use our website if you do not wish to be bound by this Disclaimer.
</p>

<h6>GENERAL DISCLAIMER</h6>
<p>
All content and information on this website including our programs, products and/or services is for informational and educational purposes only, does not constitute professional advice of any kind and does not establish any kind of professional-client relationship by your use of this website. A professional-client relationship with you is only formed after we have expressly entered into a written agreement with you that you have signed including our fee structure and other terms to work with you in a specific matter. Although we strive to provide accurate general information, the information presented here is not a substitute for any kind of professional advice, and you should not rely solely on this information. Always consult a professional in the area for your particular needs 
</p>

<h6>EARNINGS DISCLAIMER, TESTIMONIALS AND OTHER DISCLAIMERS</h6>

<p>We may disclose our income reports and success results of our current or former customers including product reviews and testimonials on the website from time to time. These income reports, product reviews, and testimonials are accurate and strictly for informational purposes only.
We share this information as examples with you but it does not serve as a guarantee or promise of any kind for your results and successes if you decide to use the same information, reviews, products, services, tips, and techniques offered here.
All the testimonials included on our websites, programs, products and/or services are real-world examples and stories of other people’s experiences with our programs, products and/or services. But they are not intended to serve as a guarantee that you will achieve the same or similar results. Each individual’s performance is different and your results will vary accordingly.
You are encouraged to perform your own due diligence and research and are solely responsible for your earnings and results. Your earning potential and results are contingent upon your personal circumstances, abilities, experience, and skills. Therefore, you agree not to hold us and/or our officers, employees, successors, shareholders, joint venture partners or anyone else working with us liable for any of your successes or failures directly or indirectly related to the information, reports, reviews, products and/or services presented to you here.
</p>

<h6>NO WARRANTIES</h6> 
<p>ALL CONTENT, INFORMATION, PRODUCTS AND/OR SERVICES ON THE WEBSITE ARE “AS IS” AND “AS AVAILABLE” BASIS WITHOUT ANY REPRESENTATIONS OR WARRANTIES OF ANY KIND INCLUDING THE WARRANTIES OF MERCHANTABILITY OR FITNESS FOR ANY PURPOSE, EXPRESS OR IMPLIED TO THE FULL EXTENT PERMISSIBLE BY LAW. COMPANY MAKES NO REPRESENTATIONS OR WARRANTIES AS TO THE CONTENT, INFORMATION, MATERIALS, PRODUCTS, AND/OR SERVICES PROVIDED ON THIS WEBSITE. COMPANY MAKES NO WARRANTIES THAT THE WEBSITE WILL PERFORM OR OPERATE TO MEET YOUR REQUIREMENTS OR THAT THE INFORMATION PRESENTED HERE WILL BE COMPLETE, CURRENT, OR ERROR-FREE. COMPANY DISCLAIMS ALL WARRANTIES, IMPLIED AND EXPRESS FOR ANY PURPOSE TO THE FULL EXTENT PERMITTED BY LAW.
</p>

<h6>LIMITATION OF LIABILITY</h6>

<p>You agree that under no circumstances, we and/or our officers, employees, successors, shareholders, joint venture partners, or anyone else working with us shall be liable for any direct, indirect, incidental, consequential, equitable, special, punitive, exemplary or any other damages resulting from your use of this website including but not limited to all the content, information, products, services and graphics presented here. 

You expressly agree that your use of the website is at your sole risk and that you are solely responsible for the accuracy of the personal and any information you provide, the outcome of your actions, personal and business results, and for all other use in connection with the website.

You also expressly agree that we and/or our officers, employees, successors, shareholders, joint venture partners or anyone else working with us shall not be liable to you for any damages resulting from 1) any errors or omissions on the website, delay or denial of any products or services, failure of performance of any kind, interruption in the operation and your use of the website, website attacks including computer virus, hacking of information, and any other system failures; 2) any loss of income, use, data, revenue, profits, business or any goodwill related to the website; 3) any theft or unauthorized access by the third party of your information from the website regardless of our negligence; and 4) any use or misuse of the information, products and/or services offered here. 

This limitation of liability shall apply whether such liability arises from negligence, breach of contract, tort or any other legal theory of liability. You agree that we provide no express or implied guarantees to you for the content presented here, and you accept that no particular results are being promised to you here. 
</p>
<h6>INDEMNIFICATION</h6>
<p>You agree to indemnify and hold the Company and/or its officers, employees, successors, shareholders, joint venture partners, or anyone else working with us harmless from all losses, claims, damages, demands, actions, suits, proceedings, or judgments, including costs, expenses and reasonable attorneys' fees ("Liabilities") assessed against or otherwise incurred by you arising, in whole or in part, from: (a) actions or omissions, whether done negligently or otherwise, by you, your agents, directors, officers, employees or representatives; (b) all your actions and use of our websites including purchasing programs, products and services; (c) violation of any laws, rules, regulations or ordinances by you; or (d) violation of any terms and conditions of this website by you or anyone related to you; e) infringement by you or any other user of your account of any intellectual property or other rights of anyone. The company will notify you promptly of any such claims or liability and reserves the right to defend such claim, liability, or damage at your expense. You shall fully cooperate and assist us if requested, without any cost, to defend any such claims.
</p>
 
 
 




     
        </div>
        
        </>
    )
}
export default Disclaimer;