import './App.css';
import Home from './pages/Home';
import Faq from './pages/Faq';
import Contact from './pages/Contact';
import Press from './pages/Press';
import TermsConditions from './pages/TermsConditions';
import Disclaimer from './pages/Disclaimer';
import PrivacyPolicy from './pages/PrivacyPolicy';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Header from './pages/components/Header';
import Footer from './pages/components/Footer';


function App() {
  return (
    <>
      <BrowserRouter>
        <div className='flex flex-col min-h-screen'>
          <Header />
          <div className='flex-grow'>
            <Routes>
              {/* PAGES ----------------------------------------------------------------- */}
              <Route exact path="/" element={<Home />} />
              <Route path="/faq" element={<Faq />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/press" element={<Press />} />
              <Route path="/terms-conditions" element={<TermsConditions />} />
              <Route path="/disclaimer" element={<Disclaimer />} />
              <Route path="/privacy-policy" element={<PrivacyPolicy />} />


            </Routes>
          </div>
          <Footer />
        </div>
      </BrowserRouter>
    </>

  );
}

export default App;
