import React, {useEffect, useState} from "react";
import logo_ghost from './../Images/logo_ghost.png'
import logo from './../Images/logo_without_ghost.png'

function Home() {

    const [message, setMessage] = useState('')

    useEffect(() => {
        fetch('/api/message')
            .then(response => response.json())
            .then(data => {
                setMessage(data.message)
            })
    }
        , [])

    return (
        <div className="page-container">
        <div className="justify-center justify-items-center grid grid-flow-row auto-rows-max">
        <div className="logo-parent">
            <img src={logo} className="logo" alt="logo" />
            <img src={logo_ghost} className="logo-ghost mascot-logo" alt="logo_ghost" />
        </div>
        <a href="https://store.steampowered.com/app/3024190/Ghost_Party/"><button className="mt-8 wishlist-button">WISHLIST ON STEAM</button></a>
        </div>
        </div>
    );
} export default Home;
