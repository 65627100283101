import React from "react";

function Faq() {
    return (
        <>
            <div className="page-container grid justify-center justify-items-center">
                <div 
                style={{maxWidth: "600px"}}
                className="grid grid-flow-row auto-rows-max">
                    <h1 className="text-center">FAQ</h1>
                    <h3>What is Ghost Party?</h3>
                    <p>Ghost Party is an open world, turn-based RPG inspired by Dungeons & Dragons and Phoenix Wright: Ace Attorney. Explore, fight, gather evidence and solve mysteries in a fantasy realm of ghosts & magic. Choose how you Interact with the world & its inhabitants. Fight or logically reason your way out of a battle. The choice is yours.</p>
                    <h3 className="mt-8">When will Ghost Party be released?</h3>
                    <p>Ghost Party is currently in development and aiming for a release in 2026 but don't quote me lol.</p>
                    <h3 className="mt-8">What platforms will Ghost Party be available on?</h3>
                    <p>Ghost Party will be available on PC: Windows, Mac, and Linux. Stay tuned for announcements for console release!</p>
                    <h3 className="mt-8">Who is developing Ghost Party?</h3>
                    <p>Ghost Party is being developed by Azriel Jack.</p>
                    <h3 className="mt-8">What engine is Ghost Party being developed in?</h3>
                    <p>Ghost Party is being developed in a custom engine using the Monogame framework.</p>
                    <h3 className="mt-8">How can I stay updated on Ghost Party?</h3>
                    <p>You can follow the development of Ghost Party on <a href="https://www.youtube.com/@azrieljack">Youtube</a>.</p>
                    <h3 className="mt-8">How can I support Ghost Party?</h3>
                    <p>Aww, you're so kind! Thank you so much. You can wishlist Ghost Party on <a href="https://store.steampowered.com/app/3024190/Ghost_Party/">Steam</a>, donate on <a href="https://www.patreon.com/azrieljack">Patreon</a> or give suggestions and feedback on <a href="https://discord.gg/pSnuA4UsRh">Discord</a>.</p>
                
                </div>
            </div>
        </>
    );
}
export default Faq;