import React from "react";
import { Link } from "react-router-dom";

function Footer() {
    return (<footer  className=" p-4 inset-x-0 bottom-0 grid justify-center justify-items-center">

        <div className="flex space-x-4">
            <Link to="/privacy-policy">Privacy Policy</Link>
            <Link to="/terms-conditions">Terms & Conditions</Link>
            <Link to="/disclaimer">Disclaimer</Link>

        </div>
    </footer>)
}
export default Footer;