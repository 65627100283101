import React from "react";

function Press() {
    return (
        <>
            <div className="page-container">
                <div className="text-center justify-center justify-items-center grid grid-flow-row auto-rows-max">
                    <h1>Press Kit</h1>
                    <p>Coming Soon (when the art gets better lol)</p>
                </div>
            </div>
        </>
    );
}
export default Press;